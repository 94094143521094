const data = [
    {
        question:
      'In social innovating, thinking like an entrepreneur includes all of these traits EXCEPT which?',
        incorrectAnswers: [
            'Creativity',
            'Natural curiosity',
            'Approaching problems with optimism',
        ],
        correctAnswer: 'Building new businesses',
    },
];

export default data;
